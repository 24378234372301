exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-app-development-js": () => import("./../../../src/pages/services/app-development.js" /* webpackChunkName: "component---src-pages-services-app-development-js" */),
  "component---src-pages-services-digital-marketing-js": () => import("./../../../src/pages/services/digital-marketing.js" /* webpackChunkName: "component---src-pages-services-digital-marketing-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-mobile-app-development-js": () => import("./../../../src/pages/services/mobile-app-development.js" /* webpackChunkName: "component---src-pages-services-mobile-app-development-js" */),
  "component---src-pages-services-server-maintenance-js": () => import("./../../../src/pages/services/server-maintenance.js" /* webpackChunkName: "component---src-pages-services-server-maintenance-js" */),
  "component---src-pages-services-support-and-management-js": () => import("./../../../src/pages/services/support-and-management.js" /* webpackChunkName: "component---src-pages-services-support-and-management-js" */),
  "component---src-pages-services-web-design-js": () => import("./../../../src/pages/services/web-design.js" /* webpackChunkName: "component---src-pages-services-web-design-js" */),
  "component---src-pages-services-web-development-js": () => import("./../../../src/pages/services/web-development.js" /* webpackChunkName: "component---src-pages-services-web-development-js" */)
}

